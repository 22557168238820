<template>
  <div class="home">
    <el-container>
      <el-header>
        <header-layout></header-layout>
      </el-header>
      <el-main>
        <div style="flex: 1;margin-left: 10%; margin-right:10%;">
          <el-row>
            <!-- 面包屑 -->
            <el-col :span="12">
              <el-breadcrumb separator-class="el-icon-arrow-right" style="height:20px; margin-top: 0.5rem">
                <el-breadcrumb-item :to="{ path: '/rank/indexbrowser' }">指标浏览器</el-breadcrumb-item>
                <el-breadcrumb-item style="font-size: 25px; line-height: 1rem;">
                  {{ this.companyInfo.name }} <span style="color: #409EFF; font-weight: bold">
                  ({{ this.companyInfo.code }})</span> ：ESG总评
                  <span style="color: #67C23A; font-weight: bold"> {{ this.ave_total }}</span> 分
                </el-breadcrumb-item>
              </el-breadcrumb>
            </el-col>

            <!-- 日期 -->
            <el-col :span="12" style="text-align:right;">

              <span>选择日期&nbsp;&nbsp;</span>
              <el-date-picker
                v-model="selectTime"
                placeholder="选择年月"
                type="month"
                value-format="yyyy-MM"
                @change="getInfo">
              </el-date-picker>
            </el-col>
          </el-row>
          <hr style="border: 1px solid gray;">
          <br>
          <!-- 基本结构化数据 -->
          <!-- <div class="basic-info" style="margin-top: 1rem;">
            <el-form :inline="true" :model="companyInfo" disabled style="float: left;">
              <el-form-item label="公司名称">
                <el-input v-model="companyInfo.name"></el-input>
              </el-form-item>
              <el-form-item label="公司代码">
                <el-input v-model="companyInfo.code"></el-input>
              </el-form-item>
              <el-form-item label="最新股价">
                <el-input v-model="companyInfo.price"></el-input>
              </el-form-item>
              <el-form-item label="ESG总总评">
                <el-input v-model="companyInfo.esg">
                </el-input>
              </el-form-item>
            </el-form>
          </div> -->
          <el-row style="margin-bottom: 40px;">
            <span style="float:left; margin-bottom: 20px; font-weight: bolder; font-size: large;">基本面数据</span>
            <el-table
              :data="struTableData1"
              style="margin-top: 0.5rem;">
              <el-table-column
                align="center"
                label="股价"
                prop="price"></el-table-column>
              <!--              <el-table-column-->
              <!--                align="center"-->
              <!--                label="ESG总总评"-->
              <!--                prop="esg"></el-table-column>-->
              <el-table-column
                align="center"
                label="每股净利润"
                prop="eps"></el-table-column>
              <el-table-column
                align="center"
                label="市盈率"
                prop="pe"></el-table-column>
              <el-table-column
                align="center"
                label="市净率"
                prop="pb"></el-table-column>
              <el-table-column
                align="center"
                label="净资产收益率"
                prop="roe"></el-table-column>
              <el-table-column
                align="center"
                label="总资产收益率"
                prop="roa"></el-table-column>
              <el-table-column
                align="center"
                label="净利润增长率"
                prop="npgr"></el-table-column>
              <el-table-column
                align="center"
                label="营业收入增长率"
                prop="oigr"></el-table-column>
            </el-table>
          </el-row>

          <!-- ESG评级 -->
          <el-row>
            <!-- ESG三个评级的雷达图 -->
            <el-col :span="5">
              <div id="radar1" ref="radar1" style="width:300px;height:300px;">

              </div>
            </el-col>

            <!-- 四个维度的雷达图 -->
            <el-col :span="5">
              <div id="radar2" ref="radar2" style="width:300px;height:300px;">

              </div>
            </el-col>

            <el-col :span="12" style="float:right;margin-bottom: 0px;">
              <div>
                <span style="float:left; margin-bottom: 20px; font-weight: bolder; font-size: large;">ESG评级表</span>
                <el-table
                  :data="scoreTableData">
                  <el-table-column
                    :filter-method="filterTag"
                    :filters="[{text: 'CSR报告', value: 'CSR报告'}, {text: '调研问答', value: '调研问答'}, {text: '分析师报告', value: '分析师报告'}, {text: '公司新闻', value: '公司新闻'}]"
                    align="center"
                    label="项目"
                    prop="project"
                    width="140">
                    <template v-slot="scope">
                      <el-tag :type="setTagType(scope.row.project)">{{ scope.row.project }}</el-tag>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :formatter="formatNum"
                    align="center"
                    label="E评分"
                    prop="score_e"
                    width="160"></el-table-column>
                  <el-table-column
                    :formatter="formatNum"
                    align="center"
                    label="S评分"
                    prop="score_s"
                    width="160"></el-table-column>
                  <el-table-column
                    :formatter="formatNum"
                    align="center"
                    label="G评分"
                    prop="score_g"></el-table-column>
                  <!-- <el-table-column
                    prop="score_esg"
                    label="总评分"
                    sortable></el-table-column> -->
                </el-table>
              </div>
            </el-col>
          </el-row>

          <!-- ESG文本 -->
          <div style="margin-top: 1rem;">
            <span style="float:left; margin-bottom: 20px; font-weight: bolder; font-size: large;">ESG文本</span>
            <el-table
              v-loading="loadingText"
              :data="textTableData"
              stripe
              style="margin-top: 0.5rem;"
              @cell-click="getTextDetail"
              @filter-change="setTextType"
              @sort-change="handleSortChange">
              <el-table-column
                :filters="[{text: 'CSR报告', value: 'CSR报告'}, {text: '调研问答', value: '调研问答'}, {text: '分析师报告', value: '分析师报告'}, {text: '公司新闻', value: '公司新闻'}]"
                align="center"
                label="类型"
                prop="project"
                width="150">
                <template v-slot="scope">
                  <el-tag :type="setTagType(scope.row.project)">{{ scope.row.project }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="标题"
                prop="title">
                <template v-slot="scope">
                  <el-tooltip content="点击查看文本详情" effect="dark" placement="right">
                    <el-link type="primary">{{ scope.row.title }}<i class="el-icon-view el-icon--right"></i></el-link>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="发布机构"
                prop="issuer"
                width="150"></el-table-column>
              <el-table-column
                align="center"
                label="发布时间"
                prop="date_release"
                sortable='custom'
                width="150"></el-table-column>
            </el-table>
          </div>

          <div style="float: right; margin-top: 2rem; margin-bottom: 2rem;">
            <el-pagination
              :current-page.sync="currentTextPage"
              :page-size.sync="pageTextSize"
              :page-sizes="[5, 10]"
              :total="totalTextNum"
              background
              hide-on-single-page
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="getTextInfo"
              @current-change="getTextInfo">
            </el-pagination>
          </div>
        </div>
      </el-main>
      <FooterLayoutSlim></FooterLayoutSlim>
    </el-container>
    <el-backtop target=".home">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import HeaderLayout from '../../components/HeaderLayout.vue'
import FooterLayoutSlim from '../../components/FooterLayoutSlim'
import {getBasicInfo, getESGRatings, getFundamentalsInfo, getTextData} from '@/api/company'
import {getFixedN} from '@/utils'

export default {
  name: 'company',
  components: {
    HeaderLayout,
    FooterLayoutSlim
  },
  data() {
    return {
      latestYear:'',
      latestMonth:'',
      companyInfo: {
        name: '平安银行',
        code: '000001',
        price: '15.03',
        esg: '2.2222'
      },
      ave_total: -1,
      filterTextTypes: [true, true, true, true],
      currentTextPage: 1,
      pageTextSize: 10,
      totalTextNum: 100,
      selectTime: '',
      selectYear:this.latestYear,
      selectMonth: this.latestYear + '-' + this.latestMonth,
      scoreTableData: [
        {
          project: '分析师报告'
        }, {
          project: 'CSR报告'
        }, {
          project: '公司新闻'
        }, {
          project: '调研问答'
        }],
      textTableData: [],
      radarChart1: [],
      radarChart2: [],
      struTableData1: [],
      struTableData2: [],
      struTableData3: [],
      stru3: [
        {
          label: '本科生数',
          pname: 'n_under'
        }, {
          label: '技术人员数量',
          pname: 'n_rd'
        }, {
          label: '审计意见2015-2021',
          pname: 'ppinion'
        }, {
          label: '诉讼仲裁2015-2021',
          pname: 'litigation'
        }, {
          label: '违规',
          pname: 'violation'
        }, {
          label: '违规2015-2021',
          pname: 'violation_2015'
        }, {
          label: '研究生数',
          pname: 'n_gradu'
        }, {
          label: '员工总数',
          pname: 'n_total'
        }, {
          label: '主营业务成本占比',
          pname: 'main_c_p'
        }, {
          label: '主营业务利润占比',
          pname: 'main_p_p'
        }, {
          label: '主营业务收入占比',
          pname: 'main_i_p'
        }, {
          label: '扶贫',
          pname: 'help_poor'
        }],
      textType: {
        ana: '分析师报告',
        csr: 'CSR报告',
        news: '公司新闻',
        qa: '调研问答'
      },
      loadingText: false,
      sortedOrder: 'd'
    }
  },
  methods: {
    getInfo() {
      const t = new Date(this.selectTime)
      this.selectYear = t.getFullYear()
      this.selectMonth = t.getMonth() + 1
      console.log(this.selectTime)
      console.log(this.selectYear)
      console.log(this.selectMonth)
      // 获取ESG评分
      this.getScoreInfo()

      this.getBasicInfo()
      this.getScoreInfo()
      this.getFundamentals()
      setTimeout(this.drawRadar1, 500)
      setTimeout(this.drawRadar2, 500)
    },
    filterTag(value, row) {
      return row.project === value
    },
    formatNum(row, column, cellValue) {
      if (typeof cellValue === 'undefined' || cellValue.length < 1) return ''
      let num = cellValue
      num = parseFloat(num)
      num = Math.round(num * Math.pow(10, 4)) / Math.pow(10, 4)
      num = Number(num).toFixed(4)
      return num + ''
    },
    getFundamentals() {
      getFundamentalsInfo(this.companyInfo.code, parseInt(this.selectYear), parseInt(this.selectMonth)).then(res => {
        console.log(res)
        if (res.code !== 0) {
          this.$message({
            message: '暂无基本面数据',
            type: 'warning',
            center: true
          })
          return
        }
        this.struTableData1.splice(0, this.struTableData1.length)
        res.data.price = this.companyInfo.price
        res.data.esg = this.companyInfo.esg
        this.struTableData1.push(res.data)
        console.log(this.struTableData1)
      })
    },
    getBasicInfo() {
      // 获取公司四项基本信息
      getBasicInfo(this.companyInfo.code, null).then(res => {
        if (res.code !== 0) {
          this.$message({
            message: '暂无股价或ESG总总评',
            type: 'warning',
            center: true
          })
          return
        }
        this.companyInfo.price = res.data.stock_value
        this.companyInfo.name = res.data.company_name
        this.companyInfo.esg = parseFloat(res.data.esg_general_score).toFixed(4)
        // this.$message({
        //   message: '基本信息加载成功',
        //   type: 'success',
        //   center: true
        // })
      })
    },
    // 获取公司表格内评分 依据对应的年月
    getScoreInfo() {
      // const month = parseInt(this.selectMonth.substring(0, this.selectMonth.length - 1))
      this.scoreTableData = [
        {
          project: '分析师报告'
        }, {
          project: 'CSR报告'
        }, {
          project: '公司新闻'
        }, {
          project: '调研问答'
        }]
      getESGRatings(this.companyInfo.code, parseInt(this.selectYear), parseInt(this.selectMonth)).then(res => {
        // console.log(res)
        if (res.code !== 0) {
          this.$message({
            message: '暂无ESG评分数据',
            type: 'warning',
            center: true
          })
          return
        }
        this.radarChart1 = []
        this.radarChart2 = []
        const tmp = []
        const projects = ['分析师报告', 'CSR报告', '公司新闻', '调研问答']
        const prefix = ['ana_', 'csr_', 'news_', 'qa_']
        for (let i = 0; i < 4; i++) {
          tmp.push({
            project: projects[i],
            score_e: res.data[prefix[i] + 'e'],
            score_s: res.data[prefix[i] + 's'],
            score_g: res.data[prefix[i] + 'g']
          })
          this.radarChart2.push(
            String(
              (res.data[prefix[i] + 'e'] +
                res.data[prefix[i] + 's'] + res.data[prefix[i] + 'g']) / 3
            ).replace(/^(.*\..{4}).*$/, '$1'))
        }

        this.radarChart1.push(
          String(
            (res.data.ana_e +
              res.data.csr_e + res.data.news_e + res.data.qa_e) / 4
          ).replace(/^(.*\..{4}).*$/, '$1')
        )
        this.radarChart1.push(
          String(
            (res.data.ana_s +
              res.data.csr_s + res.data.news_s + res.data.qa_s) / 4
          ).replace(/^(.*\..{4}).*$/, '$1')
        )
        this.radarChart1.push(
          String(
            (res.data.ana_g +
              res.data.csr_g + res.data.news_g + res.data.qa_g) / 4
          ).replace(/^(.*\..{4}).*$/, '$1')
        )

        this.scoreTableData = tmp
        // console.log(this.scoreTableData)

        // 三维表数据
        // console.log("ESG")
        // console.log(this.radarChart1)

        // 四维表数据
        // console.log("分析师")
        // console.log(this.radarChart2)

        // this.$message({
        //   message: 'ESG评分加载成功',
        //   type: 'success',
        //   center: true
        // })
        this.ave_total = res.data.ave_total
      })
    },
    // 公司文本类别检索
    setTextType(column) {
      this.currentTextPage = 1
      if (Object.values(column)[0].length < 1) this.filterTextTypes = [true, true, true, true]
      else {
        const types = ['分析师报告', 'CSR报告', '公司新闻', '调研问答']
        for (let i = 0; i < types.length; i++) {
          if (Object.values(column)[0].includes(types[i])) this.filterTextTypes[i] = true
          else this.filterTextTypes[i] = false
        }
      }
      this.getTextInfo()
    },
    handleSortChange(tmp) {
      const sortKey = tmp.prop
      this.sortedOrder = String(tmp.order).substring(0, 1)
      this.getTextInfo()
    },
    // 获取公司文本数据
    getTextInfo() {
      this.loadingText = true
      getTextData(this.companyInfo.code, this.currentTextPage, this.pageTextSize, this.filterTextTypes, this.sortedOrder).then(res => {
        this.textTableData.splice(0, this.textTableData.length)
        if (res.code !== 0) {
          this.$message({
            message: '暂无ESG文本数据',
            type: 'warning',
            center: true
          })
          this.loadingText = false
          return
        }
        this.totalTextNum = res.data.totalNum
        res.data.list.forEach(oneText => {
          this.textTableData.push({
            project: this.textType[oneText.type],
            title: oneText.title,
            date_release: oneText.date,
            issuer: oneText.issuer,
            text_id: oneText.id
          })
        })
        this.loadingText = false
        // this.$message({
        //   message: '文本列表加载成功',
        //   type: 'success',
        //   center: true
        // })
      })
    },

    getTextDetail(row, column, cell, event) {
      // 跳转至对应文本详情页
      if (column.label === '标题') {
        this.$router.push({
          path: '/esgtext',
          query: {
            text_id: row.text_id,
            issuer: row.issuer,
            code: this.companyInfo.code,
            currentPage: this.currentTextPage,
            pageSize: this.pageTextSize
          }
        })
      }
    },
    setTagType(project) {
      switch (project) {
        case '分析师报告':
          return 'warning'
        case 'CSR报告':
          return 'primary'
        case '公司新闻':
          return 'danger'
        case '调研问答':
          return 'success'
        default:
          return 'info'
      }
    },
    drawRadar1() {
      // if(this.radar1 !==null){
      //   this.radar1.dispose()
      // }
      this.radar1 = echarts.init(this.$refs.radar1)

      const option = {
        title: {
          text: 'ESG三维雷达图'
        },
        tooltip: {
          trigger: 'axis'
        },
        radar: [
          {
            indicator: [
              {
                name: 'E维度',
                max: 10
              },
              {
                name: 'S维度',
                max: 10
              },
              {
                name: 'G维度',
                max: 10
              }
            ],
            center: ['50%', '50%'],
            radius: 80,
            axisName: {
                color: '#000000'
            }
          }

        ],
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item',
              valueFormatter: (value) => value.toFixed(4),
              textStyle: {
                align: 'left',
                fontSize: '14'
              }
            },
            areaStyle: {},
            data: [
              {
                value: [(Number(this.radarChart1[0])).toFixed(4),
                  (Number(this.radarChart1[1])).toFixed(4),
                  (Number(this.radarChart1[2])).toFixed(4)],
                name: 'ESG三维总评'
              }
            ]
          }
        ]
      }

      this.radar1.clear()
      this.radar1.setOption(option, true)
      // console.log("雷达图1绘制成功")
    },
    drawRadar2() {
      this.radar2 = echarts.init(this.$refs.radar2)
      const option = {
        title: {
          text: '项目四维雷达图'
        },
        tooltip: {
          trigger: 'axis'
        },
        radar: [
          {
            indicator: [
              {
                name: '分析师报告',
                max: 10
              },
              {
                name: 'CSR报告',
                max: 10
              },
              {
                name: '公司新闻',
                max: 10
              },
              {
                name: '调研问答',
                max: 10
              }
            ],
            center: ['50%', '50%'],
            radius: 80,
            axisName: {
                color: '#000000'
            }
          }

        ],
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item',
              valueFormatter: (value) => value.toFixed(4),
              textStyle: {
                align: 'left',
                fontSize: '14'
              }
            },
            areaStyle: {},
            data: [
              {
                value: [getFixedN(Number(this.radarChart2[0]), 4),
                  getFixedN(Number(this.radarChart2[1]), 4),
                  getFixedN(Number(this.radarChart2[2]), 4),
                  getFixedN(Number(this.radarChart2[3]), 4)],
                name: '项目四维总评'
              }
            ]
          }
        ]
      }
      this.radar2.clear()
      this.radar2.setOption(option, true)
      // console.log("雷达图2绘制成功")
    }
  },
  mounted() {
    this.getBasicInfo()
    this.getScoreInfo()
    this.getFundamentals()

    this.getTextInfo()
    setTimeout(this.drawRadar1, 500)
    setTimeout(this.drawRadar2, 500)
  },
  created() {
    this.companyInfo.code = this.$route.query.code || '000002'
    this.currentTextPage = this.$route.query.currentPage || 1
    this.pageTextSize = this.$route.query.pageSize || 10
    console.log(this.$store.state.date)
    // console.log("hello")
    this.latestMonth=this.$store.state.date.split('-')[1] || '3'
    this.latestYear=this.$store.state.date.split('-')[0] || '2022'
    console.log(this.latestMonth)

    this.selectYear = this.$route.query.year || '2021'
    this.selectMonth = parseInt(this.$route.query.month || '12') + ''
    this.selectTime = new Date(this.selectYear, this.selectMonth, 0)
    // console.log(this.selectYear)
    // console.log(this.selectMonth)
    // console.log(this.selectTime)
    // this.selectMonth += '月'
    if (localStorage.getItem('store')) {
      console.log('has token')
      this.$store.commit('replace', JSON.parse(localStorage.getItem('store')))
    }
    console.log(JSON.parse(localStorage.getItem('store')))
  }
}
</script>

<style>
.el-header {
  background-color: #34495E;
  margin-top: 0;
}

.el-aside {
  background-color: #D3DCE6;
}

.el-main {
  background-color: #E9EEF3;
}

.basic-info .el-input__inner {
  color: #1E90FF !important;
  font-size: 18px;
}

.basic-info .el-input {
  width: 7rem;
}

html, body {
  height: 100%;
}

.home {
  height: 100%;
  overflow-x: hidden;
}

table th,
table td {
  border-bottom: none !important;
}

.el-table::before {
  /* 去除下边框 */
  height: 0;
}
</style>
